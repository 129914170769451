import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    maxWidth: '1440px',
    margin: 'auto',
    flex: 1,
    paddingLeft: '5%',
    paddingRight: '5%',
    position: 'relative',
    lineHeight: '1.75',
    marginBottom: '2em',
  },
  image: {
    float: 'right',
    width: '50%',
    margin: '0 0 1em 1em',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      clear: 'both',
      width: '100%',
    },
  },
  heroImageLg: {
    borderRadius: '8px',
    width: '100%',
  },
  heroImageSm: {
    borderRadius: '8px',
    width: '100%',
    margin: '1em 0',
  },
  heroFeature: {
    display: 'flex',
  },
  articleLink: {
    textDecoration: 'none',
    color: 'black',
    '&:hover h3, &:hover h4, &:hover h5, &:hover h6': {
      textDecoration: 'underline',
    },
  },
});

export default withStyles(styles);
