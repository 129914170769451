import withStyles from '@material-ui/core/styles/withStyles';
import * as StyleConstants from 'shared/constants/styleConstants';

const styles = theme => ({
  container: {
    padding: theme.spacing(3),
  },
  iconContainer: {
    backgroundColor: StyleConstants.primaryColorDark,
    borderRadius: '100%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(5),
    height: '150px',
    width: '150px',
    [theme.breakpoints.only('md')]: {
      height: '125px',
      width: '125px',
    },
  },
  submitButton: {
    boxShadow: 'none',
    fontWeight: '400',
    flexWrap: 'nowrap',
    alignItems: 'center',
    cursor: 'pointer',
    minWidth: theme.spacing(6),
    color: 'white',
    textTransform: 'uppercase',
    borderRadius: '1.5rem',
    borderWidth: '2px',
    backgroundColor: theme.palette.secondary.main,
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      outline: 0,
      borderWidth: '2px',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  greyButton: {
    color: 'black !important',
    backgroundColor: '#cccccc !important',
    '&:hover': {
      backgroundColor: '#bbbbbb !important',
    },
  },
  successButton: {
    color: 'white !important',
    backgroundColor: '#28a745 !important',
    '&:hover': {
      backgroundColor: '#218838 !important',
    },
  },
  successMessage: {
    padding: '0.75rem 1.25rem',
    marginBottom: '1rem',
    border: '1px solid transparent',
    borderRadius: '0.25rem',
    color: '#155724',
    backgroundColor: '#d4edda',
    borderColor: '#c3e6cb',
    display: 'flex',
    alignItems: 'center',
  },
  errorMessage: {
    padding: '0.75rem 1.25rem',
    marginBottom: '1rem',
    border: '1px solid transparent',
    borderRadius: '0.25rem',
    color: '#721c24',
    backgroundColor: '#f8d7da',
    borderColor: '#f5c6cb',
    display: 'flex',
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: '3em',
    paddingTop: '3em',
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      justifyContent: 'space-around',
      paddingRight: '3em',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  title: {
    color: 'white',
    padding: '1.5rem 0',
    fontSize: '3.25rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: 'auto',
      fontSize: '42px',
      maxWidth: '650px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px',
    },
  },
  subtitle: {
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: 'auto',
      maxWidth: '650px',
      fontSize: '32px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
    },
  },
  icon: {
    margin: 'auto',
    height: '55%',
    width: '55%',
  },
  description: {
    color: 'white',
    whiteSpace: 'pre-line',
  },
  heroContent: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: StyleConstants.primaryColorDark,
    margin: '0px auto',
    width: '100%',
  },
  topWave: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '-1px',
    pointerEvents: 'none',
    minWidth: '100vw',
    width: '100%',
    height: '185px',

    [theme.breakpoints.down('sm')]: {
      width: '768px',
      height: '115px',
    },
  },
  bottomWave: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    left: '0',
    bottom: '0',
    minWidth: '100vw',
    width: '100%',
    height: '265px',
    pointerEvents: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '768px',
      height: '115px',
    },
    [theme.breakpoints.down('xs')]: {
      left: '-25%',
    },
    transform: 'scaleX(-1)', // Flip image
  },
  heroSubContent: {
    paddingTop: theme.spacing(2),
    margin: '1em auto',
    width: '100%',
    maxWidth: '1440px',
  },
  innerContent: {
    maxWidth: '1440px',
    margin: 'auto',
    flex: 1,
    paddingLeft: '5%',
    paddingRight: '5%',
  },
  innerSubContent: {
    maxWidth: '1440px',
    margin: 'auto',
    flex: 1,
    paddingLeft: '5%',
    paddingRight: '5%',
    marginTop: '-10em',
    zIndex: '10',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-5em',
    },
  },
  image: {
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      height: '740px',
      marginTop: '3.25em',
      marginRight: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  textField: {
    width: 'fill-available',
    background: 'white',
    height: '1.1876em',
    display: 'block',
    padding: '18.5px 14px',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 30px)',
    },
  },
  selectBox: {
    width: '100%',
    background: 'white',
    display: 'block',
    padding: '18.5px 14px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: 'inset 0 1px 2px rgba(0,0,0,0.1)',
    fontSize: '16px',
    color: '#333',
    transition: 'border-color 0.3s',
    '&:focus': {
      borderColor: '#66afe9',
      outline: '0',
      boxShadow:
        'inset 0 1px 2px rgba(0,0,0,0.1), 0 0 5px rgba(102, 175, 233, 0.6)',
    },
  },
  selectOption: {
    padding: '10px',
    background: 'white',
    color: '#333',
  },
});

export default withStyles(styles);
