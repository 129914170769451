import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Student from 'shared/assets/images/student-03.png';
import StudentContact from 'shared/assets/images/student-04.png';
import PrimaryButtonV4 from '../../../shared/components/Buttons/PrimaryButtonV4';
import FeatureLink from '../../components/FeatureLink/FeatureLink';
import withStyles from './ForSchoolsStyles';
import FeatureHero from '../../components/FeatureHero/FeatureHero';
import TestimonialHero from '../../components/TestimonialHero/TestimonialHero';
import NetworkPartners from '../../components/NetworkPartners/NetworkPartners';
import IconOne from './services-icon-1.svg';
import IconTwo from './services-icon-2.svg';
import IconThree from './services-icon-3.svg';
import IconCurriculum from './icon-curriculum.svg';
import IconCustomerSupport from './icon-customer-support.svg';
import IconData from './icon-data.svg';
import BlogHero from './blog/BlogHero';
import BlueNav from '../../components/BlueNav/BlueNav';
import SalesforceForm from '../../components/SalesforceForm/SalesforceForm';

const ForSchools = ({ classes }) => (
  <div className={classes.ForSchools}>
    <BlueNav activeLink="1" menuType="forSchools" />
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      className={classes.pageTitleContainer}
    >
      <Typography className={classes.breadcrumb}>
        Home &gt; Tutoring for Schools
      </Typography>

      <Typography variant="h2" className={classes.pageTitle}>
        Tutorfly For Schools
      </Typography>

      <Typography variant="h5">
        High-impact tutoring for K-12 students delivering proven results
      </Typography>
    </Box>

    {/* <div className={classes.pageSubtitle}>
    </div> */}

    <FeatureHero
      subtitle="Our Services"
      buttonText="Request more information"
      link="#contact"
      title="Customized Tutoring Interventions for Schools"
      image={Student}
      buttonColor="orange"
    >
      <p>
        At Tutorfly, we provide personalized online and in-person tutoring
        services to support students in reaching their academic goals. Our
        experienced tutors are dedicated to helping every student unlock their
        full potential. We offer accessible and engaging tutoring sessions, with
        user-friendly tools that seamlessly integrate with your SSO platform.
      </p>
      <p>
        We strive to bridge the opportunity gap by offering in-school and
        after-school tutoring support. Our approach includes implementing
        individualized small group sessions that support students and teachers
        in the classroom, both in-person and online.
      </p>
      <p>
        Our commitment is to ensure that every student receives the tailored
        academic support they need to succeed.
      </p>
    </FeatureHero>
    <div className={classes.heroSubContent}>
      <div className={classes.innerSubContent}>
        <Box textAlign="center" mt={6} mb={2}>
          <Typography variant="h4" className={classes.sectionHeader}>
            Our Services
          </Typography>

          <Typography variant="h6" style={{ fontWeight: 'normal' }}>
            Customized tutoring interventions for schools
          </Typography>
        </Box>
        <Grid container>
          <Grid xs={12} md={4} item>
            <FeatureLink
              title="High-Impact Programs"
              icon={IconOne}
              description="Implement a high-impact, high- dosage tutoring program at your school or district. Our team of implementation specialists will work with you to seamlessly integrate scheduled tutoring sessions into your schedule. Students can work with our high quality team of tutors both online and in-person."
            />
          </Grid>
          <Grid xs={12} md={4} item>
            <FeatureLink
              title="On-Demand Programs"
              icon={IconTwo}
              description="Curate a program for your students to receive on demand tutoring support outside of school hours. Works great for supplemental homework help or test preparation. Tutorfly always uses live, synchronous tutors. Students will never talk to a computer or automated program."
            />
          </Grid>
          <Grid xs={12} md={4} item>
            <FeatureLink
              title="Custom Programs"
              icon={IconThree}
              description="Contact us today to design a custom tutoring program that fits your specific needs."
            />
          </Grid>
        </Grid>
      </div>
    </div>

    <PrimaryButtonV4
      color="orange"
      href="#contact"
      style={{ margin: '2em auto', display: 'block', width: 'fit-content' }}
    >
      Request more information
    </PrimaryButtonV4>

    <div className={classes.heroSubContent} id="method">
      <div
        className={classes.innerSubContent}
        style={{ textAlign: 'center', marginTop: '1em' }}
      >
        <Box textAlign="center" mt={6} mb={2}>
          <Typography variant="h4" className={classes.sectionHeader}>
            Our Method
          </Typography>

          <Typography variant="h6" style={{ fontWeight: 'normal' }}>
            High-impact tutoring for K-12 students delivering proven results
          </Typography>
        </Box>
        <Grid container>
          <Grid xs={12} md={4} item>
            <FeatureLink
              title="Curriculum and Tutors"
              icon={IconCurriculum}
              description="Tutorfly provides the highest quality tutors to present our standards- aligned curriculum to your students. Our team works diligently to ensure that lessons are fun, engaging, and effective. Our team of tutors is carefully selected and trained to educate and uplift young learners."
            />
          </Grid>
          <Grid xs={12} md={4} item>
            <FeatureLink
              title="Data and Analytics"
              icon={IconData}
              description="Our online platform gathers and stores data with ease. School administrators can view student progress and other important data points through a myriad of customizable reports. Tutorfly's technology provides a clear picture of student attendance and growth."
            />
          </Grid>
          <Grid xs={12} md={4} item>
            <FeatureLink
              title="Fast Customer Support"
              icon={IconCustomerSupport}
              description="At Tutorfly, we know that things come up and you may need to access our core team at any moment. We have representatives dedicated to supporting your program during all hours of tutoring. We are committed to ensuring you have all the support you need."
            />
          </Grid>
        </Grid>
      </div>
    </div>

    <PrimaryButtonV4
      color="orange"
      href="#contact"
      style={{ margin: '2em auto', display: 'block', width: 'fit-content' }}
    >
      Request more information
    </PrimaryButtonV4>
    <br />
    <div>
      <TestimonialHero />
      <div id="blog" />
    </div>
    <br />
    <div>
      <BlogHero />
    </div>
    <br />

    <div>
      <NetworkPartners />
      <div id="contact" />
    </div>
    <div>
      <SalesforceForm
        subtitle="Learn More"
        title="Request more information about the Tutorfly for Schools program"
        image={StudentContact}
      />
    </div>
  </div>
);

ForSchools.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line
};

export default withStyles(ForSchools);
