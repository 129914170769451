import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import withStyles from './BlogStyles';
import image1 from './1/image.jpg';
import image2 from './2/image.jpg';
import image3 from './3/image.jpg';
import image4 from './4/image.jpg';
import image5 from './5/image.jpg';

const ForSchools = ({ classes }) => (
  <div className={classes.container}>
    <Grid container alignItems="center" spacing={6}>
      <Grid item xs={12} md={6}>
        <a href="/schools/blog/5" className={classes.articleLink}>
          <img src={image5} className={classes.heroImageLg} alt="" />
        </a>
      </Grid>
      <Grid item xs={12} md={6}>
        <a href="/schools/blog/5" className={classes.articleLink}>
          <Typography variant="h5">Tutoring Articles</Typography>
          <Typography variant="h4" className={classes.heroImageSm}>
            Combatting Learning Loss with High Dosage Tutoring
          </Typography>
          <b>Ashley Jones | 25 October 2022 | 3 mins</b>
          <p>
            Quality EdTech allows learning to go far beyond a textbook. Kids
            become immersed in the subject matter through engaging tools like
            videos, animation, games, and more. Learning the many facets of a
            cell on paper is wonderful. But zooming in on the layers of a
            mitochondria from a 360 angle provokes wonder.
          </p>
        </a>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <a href="/schools/blog/4" className={classes.articleLink}>
          <Typography variant="h5">
            Low scores signal high need for tutors
          </Typography>
          <img src={image4} className={classes.heroImageSm} alt="" />
          <Typography variant="h6">
            How tutoring is a powerful tool for change.
          </Typography>
        </a>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <a href="/schools/blog/3" className={classes.articleLink}>
          <Typography variant="h5">
            Building equity in the classroom{' '}
          </Typography>
          <img src={image3} className={classes.heroImageSm} alt="" />
          <Typography variant="h6">
            Equity, not equality will drive change.{' '}
          </Typography>
        </a>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <a href="/schools/blog/2" className={classes.articleLink}>
          <Typography variant="h5">
            Lifting literacy through tutoring{' '}
          </Typography>
          <img src={image2} className={classes.heroImageSm} alt="" />
          <Typography variant="h6">
            Literacy is a life skill for outside the classroom.{' '}
          </Typography>
        </a>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <a href="/schools/blog/1" className={classes.articleLink}>
          <Typography variant="h5">
            How EdTech helps<br />students{' '}
          </Typography>
          <img src={image1} className={classes.heroImageSm} alt="" />
          <Typography variant="h6">
            The benefits of EdTech are undeniable.{' '}
          </Typography>
        </a>
      </Grid>
    </Grid>
  </div>
);

ForSchools.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line
};

export default withStyles(ForSchools);
