import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TopWave from 'shared/assets/svg/rounded-wave-bottom-inverse.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import { isNotEmpty, isPhoneValid, emailIsValid } from 'shared/utilities/utils';
import withStyles from './SalesforceFormStyles';
import PrimaryButtonV4 from '../../../shared/components/Buttons/PrimaryButtonV4';

const SalesforceForm = ({
  title,
  subtitle,
  description,
  image,
  classes,
  showWave,
}) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    title: '',
    company: '',
    email: '',
    phone: '',
    state: '',
  });

  const [formStatus, setFormStatus] = useState({
    isSubmitting: false,
    captchaResponse: '',
    message: null,
    messageType: null,
  });

  const handleFormChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptchaChange = value => {
    setFormStatus({ ...formStatus, captchaResponse: value });
  };

  function getButtonClass(isSubmitting, messageType) {
    if (isSubmitting) return `${classes.submitButton} ${classes.greyButton}`;
    if (messageType === 'success')
      return `${classes.submitButton} ${classes.successButton}`;
    return classes.submitButton;
  }

  // Function to get the button's text
  function getButtonText(isSubmitting, messageType) {
    if (isSubmitting) return 'Submitting...';
    if (messageType === 'success') return 'Submitted';
    return 'Submit Inquiry';
  }

  const verifyCaptcha = async () => {
    try {
      const response = await fetch('/verify-captcha', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'g-recaptcha-response': formStatus.captchaResponse,
        }),
      });

      return await response.json();
    } catch (error) {
      setFormStatus({
        ...formStatus,
        message: 'Error verifying Captcha. Please try again.',
        messageType: 'error',
      });
      return { success: false };
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    // Start by assuming there is no error
    let errorMessage = null;

    if (
      !isNotEmpty(formData.first_name) ||
      !isNotEmpty(formData.last_name) ||
      !isNotEmpty(formData.title) ||
      !isNotEmpty(formData.company) ||
      !isNotEmpty(formData.email) ||
      !isNotEmpty(formData.phone) ||
      !isNotEmpty(formData.state)
    ) {
      errorMessage = 'Please complete all fields.';
    } else if (!emailIsValid(formData.email)) {
      errorMessage = 'Please enter a valid email address.';
    } else if (!isPhoneValid(formData.phone)) {
      errorMessage = 'Please enter a valid phone number.';
    }

    if (errorMessage) {
      setFormStatus({
        ...formStatus,
        message: errorMessage,
        messageType: 'error',
        isSubmitting: false,
      });
      return;
    }

    setFormStatus({ isSubmitting: true, message: null, messageType: null });

    const captchaVerificationResponse = await verifyCaptcha();

    if (!captchaVerificationResponse.success) {
      setFormStatus({
        ...formStatus,
        message: 'Captcha verification failed. Please try again.',
        messageType: 'error',
        isSubmitting: false,
      });
      return;
    }

    const formSubmissionData = new URLSearchParams({
      oid: '00D7F000006kPvF',
      retURL: 'https://www.tutorfly.com/schools/',
      recordType: '0127F000000BrJP',
      ...formData,
      lead_source: 'Web',
      FCS_REC__Country_of_Interest_Custom__c: 'United States',
      '00N7F00000PuZgl': 'Affiliation Opportunity',
      'g-recaptcha-response': captchaVerificationResponse.response,
    });

    try {
      const response = await fetch('/submit-inquiry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formSubmissionData,
      });

      if (response.ok) {
        setFormStatus({
          ...formStatus,
          message: 'Form submitted successfully! Thank you.',
          messageType: 'success',
          isSubmitting: false,
        });
      } else {
        setFormStatus({
          ...formStatus,
          message: 'Form submission failed. Please try again.',
          messageType: 'error',
          isSubmitting: false,
        });
      }
    } catch (error) {
      setFormStatus({
        ...formStatus,
        message:
          'An error occurred while submitting the form. Please try again.',
        messageType: 'error',
        isSubmitting: false,
      });
    }
  };

  const { isSubmitting, message, messageType } = formStatus;

  return (
    <div className={classes.heroContent}>
      {showWave && (
        <Grid item className={classes.topWave}>
          <img src={TopWave} width="100%" alt="" />
        </Grid>
      )}
      <div className={classes.innerContent}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            md={6}
            lg={7}
            alignItems="center"
            justify="center"
          >
            <img
              className={classes.image}
              src={image}
              alt="A Tutorfly Student"
            />
          </Grid>
          <Grid item container xs={12} md={6} lg={5}>
            <Grid
              container
              justify="center"
              alignItems={{
                xs: 'start',
                lg: 'center',
              }}
              direction="column"
              wrap="nowrap"
              className={classes.textContainer}
              item
              xs={12}
            >
              <Grid item>
                <Typography variant="h4" className={classes.subtitle}>
                  {subtitle}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h2" className={classes.title}>
                  {title}
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: '1rem' }}>
                <p className={classes.description}>{description}</p>
              </Grid>
              <Grid item>
                <form onSubmit={handleSubmit}>
                  <input
                    id="first_name"
                    placeholder="First name"
                    className={classes.textField}
                    required
                    maxLength="40"
                    name="first_name"
                    size="20"
                    type="text"
                    value={formData.first_name}
                    onChange={handleFormChange}
                    disabled={isSubmitting || messageType === 'success'}
                  />
                  <br />

                  <input
                    id="last_name"
                    placeholder="Last name"
                    className={classes.textField}
                    required
                    maxLength="80"
                    name="last_name"
                    size="20"
                    type="text"
                    value={formData.last_name}
                    onChange={handleFormChange}
                    disabled={isSubmitting || messageType === 'success'}
                  />
                  <br />

                  <input
                    id="title"
                    placeholder="Title"
                    className={classes.textField}
                    required
                    maxLength="80"
                    name="title"
                    size="20"
                    type="text"
                    value={formData.title}
                    onChange={handleFormChange}
                    disabled={isSubmitting || messageType === 'success'}
                  />
                  <br />

                  <input
                    id="company"
                    placeholder="Company"
                    className={classes.textField}
                    required
                    maxLength="40"
                    name="company"
                    size="20"
                    type="text"
                    value={formData.company}
                    onChange={handleFormChange}
                    disabled={isSubmitting || messageType === 'success'}
                  />
                  <br />

                  <input
                    id="email"
                    placeholder="Email"
                    className={classes.textField}
                    required
                    maxLength="80"
                    name="email"
                    size="20"
                    type="text"
                    value={formData.email}
                    onChange={handleFormChange}
                    disabled={isSubmitting || messageType === 'success'}
                  />
                  <br />

                  <input
                    id="phone"
                    placeholder="Phone"
                    className={classes.textField}
                    required
                    maxLength="40"
                    name="phone"
                    size="20"
                    type="text"
                    value={formData.phone}
                    onChange={handleFormChange}
                    disabled={isSubmitting || messageType === 'success'}
                  />
                  <br />

                  <select
                    id="state"
                    className={classes.selectBox}
                    required
                    name="state"
                    value={formData.state}
                    onChange={handleFormChange}
                    disabled={isSubmitting || messageType === 'success'}
                  >
                    <option value="" disabled>
                      -- Select a State --
                    </option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                    <option value="DC">District of Columbia</option>
                    <option value="AS">American Samoa</option>
                    <option value="GU">Guam</option>
                    <option value="MP">Northern Mariana Islands</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="UM">
                      United States Minor Outlying Islands
                    </option>
                    <option value="VI">Virgin Islands, U.S.</option>
                  </select>

                  <br />
                  {message && (
                    <div
                      className={
                        messageType === 'error'
                          ? classes.errorMessage
                          : classes.successMessage
                      }
                    >
                      {message}
                    </div>
                  )}
                  <ReCAPTCHA
                    sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
                    onChange={handleCaptchaChange}
                  />
                  <PrimaryButtonV4
                    type="submit"
                    style={{ marginTop: '1.5em' }}
                    className={getButtonClass(isSubmitting, messageType)}
                    disabled={isSubmitting || messageType === 'success'}
                  >
                    {getButtonText(isSubmitting, messageType)}
                  </PrimaryButtonV4>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

SalesforceForm.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  showWave: PropTypes.bool,
  classes: PropTypes.object.isRequired, // eslint-disable-line
};

SalesforceForm.defaultProps = {
  showWave: true,
};

export default withStyles(SalesforceForm);
