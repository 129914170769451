import React from 'react';
import Layout from 'shared/components/Layout/Layout';
import ForSchools from './ForSchoolsScene';

function action() {
  return {
    title: 'Tutorfly for Schools',
    chunks: ['schools'],
    component: (
      <Layout>
        <ForSchools />
      </Layout>
    ),
  };
}

export default action;
